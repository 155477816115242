@use "../util" as *;

.cards {
  display: grid;
  grid-template-columns: 1fr;

  gap: rem(30);

  @include breakpoint(medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(large) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  flex: 1;
  background-color: var(--card-bg);
  color: var(--text-color2);
  padding: rem(25);
  border-radius: rem(5);
  text-align: center;
  transition: background 150ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: var(--card-hover);
  }

  &--facebook {
    border-top: rem(5) solid var(--Facebook);
  }
  &--twitter {
    border-top: rem(5) solid var(--Twitter);
  }
  &--instagram {
    border-image: linear-gradient(
        to right,
        var(--Instagram-start),
        var(--Instagram-end)
      )
      45;

    border-width: rem(5) 0 0 0;
    border-style: solid;
  }
  &--youtube {
    border-top: rem(5) solid var(--YouTube);
  }

  &__platform {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(28);
  }

  &__subtitle {
    font-size: rem(14);
    font-weight: 700;
  }

  &__icon {
    margin-right: rem(8);
  }

  &__username {
    font-size: rem(12);
    font-weight: bold;
  }
  &__followers {
    margin-bottom: rem(25);
  }

  &__count {
    color: var(--text-color2);
    font-weight: bold;
    letter-spacing: rem(-2);
    line-height: 1;
    margin-bottom: rem(4);

    &--big {
      font-size: rem(56);
    }
    &--small {
      font-size: rem(32);
    }
  }

  &__label {
    color: var(--dark-text1);
    font-size: rem(12);
    letter-spacing: rem(5);
    font-weight: 400;
    text-transform: uppercase;
  }

  &__change {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: rem(12);
    font-weight: 700;

    &--up {
      color: var(--LimeGreen);
    }
    &--down {
      color: var(--BrightRed);
    }
  }

  img {
    margin-right: rem(4);
  }
}
