@use "../util" as *;

.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  justify-items: end;
  gap: rem(19);

  .card__subtitle {
    justify-self: start;
  }

  .card__count,
  .card__change {
    align-self: end;
  }

  .card__count {
    justify-self: start;
    margin-bottom: 0;
  }
}
