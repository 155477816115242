:root {
  --LimeGreen: hsl(163, 72%, 41%);
  --BrightRed: hsl(356, 69%, 56%);

  --Facebook: hsl(208, 92%, 53%);
  --Twitter: hsl(203, 89%, 53%);
  --Instagram-start: hsl(37, 97%, 70%);
  --Instagram-end: hsl(329, 70%, 58%);
  --YouTube: hsl(348, 97%, 39%);
  --toggle-bg-start: hsl(210, 78%, 56%);
  --toggle-bg-end: hsl(146, 68%, 55%);
  --toggle-light: hsl(230, 19%, 60%);
  --toggleBG-light: hsl(230, 22%, 74%);

  --darkBG: hsl(230, 17%, 14%);
  --darkBG-TOP: hsl(232, 19%, 15%);
  --darkBG-card: hsl(228, 28%, 20%);
  --dark-text1: hsl(228, 34%, 66%);
  --dark-text2: hsl(0, 0%, 100%);
  --darkBG-hover: hsl(228, 25%, 27%);

  --lightBG: hsl(0, 0%, 100%);
  --lightBG-hover: hsl(228, 38%, 91%);
  --lightBG-TOP: hsl(225, 100%, 98%);
  --lightBG-card: hsl(227, 47%, 96%);
  --light-text1: hsl(228, 12%, 44%);
  --light-text2: hsl(230, 17%, 14%);

  --background: var(--lightBG);
  --text-color: var(--light-text2);
  --text-color2: var(--light-text2);
  --card-bg: var(--lightBG-card);
  --card-hover: var(--lightBG-hover);
  --toggle: var(--toggle-light);
  --toggle-bg: var(--toggleBG-light);
  --toggle-button: var(--lightBG);
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: var(--darkBG);
    --text-color: var(--dark-text2);
    --text-color2: var(--dark-text1);
    --card-bg: var(--darkBG-card);
    --card-hover: var(--darkBG-hover);
    --toggle: var(--lightBG);
    --toggle-bg: linear-gradient(
      225deg,
      var(--toggle-bg-end) 0%,
      var(--toggle-bg-start) 98.02%
    );
    --toggle-button: var(--darkBG);
  }
}

body.dark {
  --background: var(--darkBG);
  --text-color: var(--dark-text2);
  --text-color2: var(--dark-text1);
  --card-bg: var(--darkBG-card);
  --card-hover: var(--darkBG-hover);
  --toggle: var(--lightBG);
  --toggle-bg: linear-gradient(
    225deg,
    var(--toggle-bg-end) 0%,
    var(--toggle-bg-start) 98.02%
  );
  --toggle-button: var(--darkBG);
}

body.light {
  --background: var(--lightBG);
  --text-color: var(--light-text2);
  --text-color2: var(--light-text2);
  --card-bg: var(--lightBG-card);
  --card-hover: var(--lightBG-hover);
  --toggle: var(--toggle-light);
  --toggle-bg: var(--toggleBG-light);
  --toggle-button: var(--lightBG);
}
