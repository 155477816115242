@use "../util" as *;

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}
h1 {
  font-size: rem(24);
  margin-bottom: rem(3);
  @include breakpoint(large) {
    font-size: rem(28);
  }
}
h2 {
  font-size: rem(24);
  margin-bottom: rem(24);
}

a,
a:active,
a:visited {
  text-decoration: none;
}
